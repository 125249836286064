import React from 'react';
import './App.css';

export function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          The <span>AI</span> that <span>Knowz</span>!
        </p>
      </header>
    </div>
  );
}
